import React from "react";
import Banner from "./banner";
import GetAKraneHome from "./homeGetAkrane";
import GetAkrane from "./getakrane";
import HireAkrane from "./hireakrane";
import HomeGallery from "./home-gallery";
import HomeHireAKrane from "./homeHireAkrane";
// import About from "./about";


function Home() {
    return (
      <div className="Home">
        <Banner />
        <GetAKraneHome />
        <HomeHireAKrane />
        <HomeGallery />
        {/* <About /> */}
      </div>
    );
  }
  
  export default Home;