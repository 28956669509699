import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';

function Header() {
  return (
    <div className="header-background">
      <Navbar collapseOnSelect expand="lg" variant="dark" style={{ background: '#0049FF' }}>
        <Navbar.Brand href="/">
        <img className='logo-Menu'
  src="img/logo.png"
  // style={{ maxWidth: '200px', height: 'auto', marginLeft: '20px' }}
  alt="Logo"
/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="custom-font" style={{ marginLeft: 'auto' }}>
            <Nav.Link className="nav4" id="txtblanco" href="./getakrane">
              GET A TOY CRANE
            </Nav.Link>
            <Nav.Link className="nav4" id="txtblanco" href="./hireakrane">
              HIRE A TOY CRANE
            </Nav.Link>
            <Nav.Link className="nav4" id="txtblanco" href="./prizes">
              PRIZES
            </Nav.Link>
            <Nav.Link className="nav4" id="txtblanco" href="./about">
              ABOUT US
            </Nav.Link>
            <Nav.Link className="nav4" id="txtblanco" href="./contact">
              CONTACT US
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
