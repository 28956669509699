import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, Switch, NavLink } from "react-router-dom";
// import ScrollToTop from 'react-router-scroll-top';

import Home from './home';
import GetAKrane from './getakrane';
import HireAKrane from './hireakrane';
import About from './about';
import Contact from './contact';
import Header from './header';
import Footer from './footer';
import Prizes from './prizes';
import HomeGallery from './home-gallery';
import HomeHireAKrane from './homeHireAkrane';



function App() {
  return (
<Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/getakrane" element={<GetAKrane />} />
      <Route path="/hireakrane" element={<HireAKrane />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/prizes" element={<Prizes />} />
      <Route path="/home-gallery" element={<HomeGallery />} />

    </Routes>
    <Footer />
  </Router>
  );
}

export default App;
